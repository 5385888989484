import React from "react";
import Footer from "src/components/Footer_gr";


class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="disclosureText">
        </div>
        <div id="page">
          <div id="limit">
            <header id="header">
              <div className="mobileHeaderContainer clearfix">
                <a href="/gr/" className="logo">
                  Piz Buin
                </a>
                <button
                  id="btnShowMenu"
                  className="collapse-btn collapse-short-btn right"
                  data-target=".collapse-menu"
                  data-toggle="collapse"
                  type="button"
                />
              </div>
              <nav id="menu" className="collapse-menu collapse-short-menu">
                <ul className="nav-header">
                  <li className="first has-sub-nav">
                    <a className="es_header" href="/gr/our-products/" target="_self">
                      ΤΑ ΠΡΟΪOΝΤΑ ΜΑΣ
                    </a>
                    <ul>
                      <li>
                        <a href="/gr/our-products/moisturising/">
                          Moisturising
                        </a>
                      </li>
                      <li>
                        <a href="/gr/our-products/hydro-infusion/">
                          Hydro Infusion
                        </a>
                      </li>
                      <li>
                        <a href="/gr/our-products/sensitive/">Sensitive</a>
                      </li>
                      <li>
                        <a href="/gr/our-products/tan_protect/">
                          Tan &amp; Protect
                        </a>
                      </li>                      
                      <li>
                        <a href="/gr/our-products/aftersun/">After Sun</a>
                      </li>
                      <li>
                        <a href="/gr/our-products/gallery/">Gallery</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a className="es_header" href="/gr/our-heritage/" target="_self">
                      Η ΜAΡΚΑ PIZ BUIN®
                    </a>
                  </li>
                  <li>
                    <a className="es_header" href="/gr/know_the_sun/" target="_self">
                      ΓΝΩΡΙΣΤΕ ΤΟΝ ΗΛΙΟ
                    </a>
                  </li>
               {/*<li>
                    <a className="es_header" href="/gr/Where-Have-You-Been/" target="_self">
                      Where Have You Been 2019
                    </a>
                  </li>*/}
                </ul>
                <ul className="countryNav">
                  <li
                    className="collapsible has-sub-nav"
                    style={{
                      "list-style": "none"
                    }}
                  >
                    <a className="countries" href="#">
                      ΧΩΡΕΣ
                    </a>
                    <ul className="subMenu">
                      <li>
                        <a href="/en/legal/contact-us/">INT</a>
                      </li>
                      <li>
                        <a href="/uk/legal/contact-us/">UK</a>
                      </li>
                      <li>
                        <a href="/es/legal/contactos/">ES</a>
                      </li>
                      <li>
                        <a href="/pt/legal/contactos/">PT</a>
                      </li>
                      <li>
                        <a href="/it/legal/contact-us/">IT</a>
                      </li>
                      <li>
                        <a href="/gr/legal/contact-us/">GR</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
              <div id="menuExtra" />
            </header>
            <section id="desktop-hero-wrapper" className="index-hero">
              <div id="bgImage">
                <div className="areaInner"></div>
              </div>
            </section>
            <section id="content">
              <div className="innerContent">
                <h2>επικοινωνια</h2>
                <p>
                  Εάν επιθυμείτε να επικοινωνήσετε μαζί μας ή εάν έχετε
                  οποιαδήποτε απορία ή οποιοδήποτε σχόλιο σχετικά με την{" "}
                  <a href="/gr/legal/private-policy/" target="_blank">
                    πολιτική απορρήτου
                  </a>{" "}
                  που τηρούμε ή την ιστοσελίδα μας, γράψτε μας σε αυτή τη
                  διεύθυνση ηλεκτρονικού ταχυδρομείου:{" "}
                  <a
                    href="https://www.ccc-consumercarecenter.com/UCUConfiguration?id=a075800001645pB"
                    target="_blank"
                  >
                    κάντε κλικ εδώ
                  </a>
                  .
                </p>
                <div> </div>
                <p> </p>
                <p> </p>
              </div>
            </section>
            <div className="clear">
              <br />
            </div>
            <Footer></Footer>
          </div>
          <div id="product-details-overlay" className="itemDescription"></div>
        </div>
        <script src="/assets/js/main.js" />
        <script src="/assets/js/vendor/jquery.fancybox.js" />
        <script src="/assets/js/vendor/jquery.fancybox-media.js" />
        <script src="/assets/js/vendor/iealert.min.js" />
         <script
          dangerouslySetInnerHTML={{
            __html:
              '\n$(document).ready(function(){\nvar i = $("#bgImage img").attr(\'src\');\n$("#bgImage").css("background-image","url("+i+")");\n$("#bgImage img").remove();\n});\n'
          }}
        />
      </div>
    );
  }
}

export default Page;
